import { Adresse } from './ApiResponse';
import { IsoDateString } from './IsoDateString';

export const BUSINESS_ID_KEY = 'businessId';
export const OE_NUMBER_KEY = 'oeNumber';
export declare const OFFER_TYPE = "offerType";

export interface CreateRequest {
    quellsystem: AemQuellsystem;
    aktionsnummer: string;
    oeNumber?: string; // Kann bei gelegenheit raus, da auf eine andere Schnittstelle verschoben (add: 30.01.2020)
}

export enum InsuranceSettingKey {
    DUMMY = 'dummy'
}

export enum BurialPackageIdentifier {
    EXKLUSIV = 'EXKLUSIV',
    KEIN = 'KEIN',
    KLASSISCH = 'KLASSISCH',
    TRADITIONELL = 'TRADITIONELL'
}

export interface UpdateRequest {
    [BUSINESS_ID_KEY]?: string;
    accountThirdParty?: boolean;
    bic?: string;
    birthdate?: IsoDateString;
    burialPackage?: BurialPackageIdentifier;
    confidentialityRelease?: boolean;
    custodian?: boolean;
    divergingInsuredPerson?: boolean;
    entitledPersons?: EntitledPerson[];
    feeDynamic?: boolean;
    flexOption?: boolean;
    iban?: string;
    vklm?: string;
    zgsl?: string;
    insuranceBegin?: IsoDateString;
    insuranceFee?: number;
    insuranceSum?: number;
    insuredPersonId?: string;
    isMakler?: boolean;
    isTiedAgent?: boolean;
    kooperationSales?: boolean;
    oeNumber?: string;
    paymentMethod?: string;
    paymentPeriod?: number;
    policyHolderId?: string;
    policyModel?: PolicyModel;
    politicallyExposedPerson?: boolean;
    variante?: string;
}

export interface EntitledPerson {
    addressValidation?: AddressValidationResults;
    entitlementPercentage: number;
    person: Person;
    relationship: string;
    relationshipFreeText?: string;
}

export interface AddressValidationResults {
    errorHousenumber?: boolean;
    errorPostalCity?: boolean;
    errorStreet?: boolean;
    isCorrectedAddress?: boolean;
    isInvalid?: boolean;
}

export interface Person {
    adresse: Adresse;
    anrede?: Addresses;
    birthdate: IsoDateString;
    email?: string;
    geburtsort?: string;
    nachname: string;
    personId?: string;
    vklm?: string; // Vermittler-Kunden-Leitmerkmal, user entered intermediary id, for example for mortuary agencies
    rufnummer?: string;
    staatsangehoerigkeit?: string;
    vorname: string;
    vorwahl?: string;
}

export enum Addresses {
    MRS = '0', MR = '1'
}

export enum PolicyModel {
    ANTRAG = 'ANTRAG',
    INVITATIO = 'INVITATIO'
}

export enum OfferType {
    DIRECT_ONLINE = 'online',
    WRITTEN_EMAIL = 'email',
    WRITTEN_POSTAL = 'post'
}

export interface OrderRequest {
    kooperationSales?: boolean;
    oeNumber?: string;
    offerType?: OfferType;
    [BUSINESS_ID_KEY]?: string;
}

export enum AemQuellsystem {
    ERGO_VERMITTLERHOMEPAGE = "ERGO_VERMITTLERHOMEPAGE",
    ERGO_INTERNET = "ERGO_INTERNET",
    ERGO_MAKLERHOMEPAGE = "ERGO_MAKLERHOMEPAGE"
}

export interface AgencyRequest {
    [BUSINESS_ID_KEY]: string;
    [OE_NUMBER_KEY]: string;
}
