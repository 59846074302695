const VKLM_MAX_LENGTH = 20;
const ZGSL_MAX_LENGTH = 4;
const NON_ALPHANUMERIC_REGEX = /\W+/g;

export const sanitizeNonAlphaNumeric = (s: string) => s.replace(NON_ALPHANUMERIC_REGEX, '');

/**
 * Check the query string params associated with makler
 * to show an error before doing anything else if one of them (or several) does't pass validation
 * @returns an array with the names of the incorrect params
 */
export const validateMaklerQueryParams = () => {
  const errors: string[] = [];
  const vklm = getQueryValue('vklm');
  const zgsl = sanitizeNonAlphaNumeric(getQueryValue('zgsl'));

  if (vklm && vklm.length > VKLM_MAX_LENGTH) {
    errors.push('VKLM');
  }
  if (zgsl && zgsl.length > ZGSL_MAX_LENGTH) {
    errors.push('ZG-SL');
  }
  return errors;
};

/**
 * Build the error message for the wrong params
 * (only length is checked so far, so the message is quite specific)
 * @param badParams array of bad params with the name of the offending param names (as generated up there)
 * @returns the custom error message listing all that it's wrong. Empty if no errors.
 */
export const buildParamsErrorString = (badParams: string[]): string => {
  if (!badParams.length) {
    return '';
  }
  const dinamicStr = badParams.reduce((str, badsy, i) => { /* eslint-disable no-magic-numbers */
    if (i > 0 && i === badParams.length - 1) { // last item of several
      return `${str} und ${badsy}`;
    } else if (i > 0) {
      return `${str}, ${badsy}`; // middle attributes
    } else {
      return badsy;
    }
  }, '');
  const initString = badParams.length > 1 ? `Die URL-Parameter ${dinamicStr} überschreiten` : `Der URL-Parameter ${dinamicStr} überschreitet`;
  return `${initString} die erlaubte Größe. Bitte wenden Sie sich an Ihren vertrieblichen Administrator.`;
};

export const getQueryValue = (key: string) => {
  const parameters: string[] = window.location.search.substring(1).split('&');
  const parameterMatcher = new RegExp(`${key}=.+`);
  const parameter = parameters.find(element => parameterMatcher.test(element)) || '';
  const queryValue = parameter.split('=')[1];

  return queryValue || '';
};
