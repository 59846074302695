import { FC } from 'react';
import { Button } from '@eg/elements/Button';
import { Modal } from '@eg/elements/components/Modal';
import { getIsMakler, getIsTiedAgent, getOeNumber } from '../helpers/modeConfig';
import { AgentContactMethods } from './AgentContactMethods';
import './ErrorModal.css';

// eslint-disable-next-line max-len
export const DEFAULT_MESSAGE = 'Ihre Aktion konnte nicht ausgeführt werden. Bitte stellen Sie sicher, dass eine Internetverbindung besteht. Versuchen Sie es dann noch einmal oder nutzen Sie unseren telefonischen Kontakt.';
export interface ErrorModalProps {
  businessId: string;
  message?: string;
  open: boolean;
  showContactFormBtn: boolean;
  onClick: () => void;
}

export const ErrorModal: FC<ErrorModalProps> = ({
  businessId,
  message = DEFAULT_MESSAGE,
  open,
  showContactFormBtn,
  onClick
}) => {
  const isMakler = getIsMakler();
  const isTiedAgent = getIsTiedAgent();
  return (
    <Modal open={open} data-component-id="error-modal">
      <h4 data-component-id="error-modal-header">
        Ups, da ist wohl etwas schief gegangen!
      </h4>
      <p className="error-modal__message">{message}</p>
      {(isMakler || isTiedAgent) && (
        <div data-component-id={isMakler ? 'error-modal-makler' : 'error-modal-tied-agent'}>
          <AgentContactMethods />
        </div>
      )}
      <div className="error-modal__primary-button">
        <Button
          onClick={onClick}
          data-component-id="error-modal-retry-button"
          size="large"
        >
          Erneut versuchen
        </Button>
      </div>
      {isTiedAgent && showContactFormBtn && (
        <Button className="error-modal__secondary-button" variant="primary">
          <a
            className="error-modal__anchor"
            data-component-id="error-modal-anchor-button"
            href={`https://www.ergo.de/de/Resources/Forms/Kontakt/Kontakt?adh_oenr=${getOeNumber()}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            Kontaktformular
          </a>
        </Button>
      )}
      <div className="error-modal__business-id">{businessId}</div>
    </Modal>
  );
};
