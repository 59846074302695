import InputRow from '@eg/elements/InputRow';
import { Field, FieldProps, FormikValues } from 'formik';
import { getErrorMessage } from '../../helpers/validationHelpers';
import { DataIdInsuredPersonPage } from '../../types/DataId';
import TooltipComponent from '../Tooltip';
import { UpdateGwgOptionsCallback } from './GwgQuestions';

export interface VKLMDataProps {
  name: string;
  defaultValue?: string;
  disabled?: boolean;
  onChange: UpdateGwgOptionsCallback;
}

export const MAX_LENGTH = 20;

const desciption = 'Tragen Sie hier die Buchstaben-/Zahlenkombination ein, die Ihnen Ihr Ansprechpartner mitgeteilt hat. Sollten Sie kein Vermittler-Kunden-Leitmerkmal erhalten haben, so lassen Sie das Feld einfach unausgefüllt.';

const CustomLabel = () => (
  <div className="vklm_custom_label">
    <div>Vermittler-Kunden-Leitmerkmal</div>
    <div className="subtext">
    (Sofern bekannt)
    </div>
  </div>
);

const VKLM = ({ name, onChange, defaultValue, disabled }: VKLMDataProps): JSX.Element => (
  <div className="vklmDiv">
    <Field
      name={name}
      render={({ field, form }: FieldProps<FormikValues>) => {
        const { setFieldValue, setFieldTouched } = form;

        return (
          <InputRow
            label={<CustomLabel />}
            id={field.name}
            name={field.name}
            disabled={disabled}
            data-component-id={DataIdInsuredPersonPage.INPUT_VKLM}
            maxLength={MAX_LENGTH}
            placeholder="Vermittler-Kunden-Leitmerkmal"
            tooltip={<TooltipComponent desc={desciption} />}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(field.name, event.target.value.trim());
              onChange({ vklm: event.target.value.trim() });
            }}
            onBlur={() => {
                setFieldTouched(field.name);
            }}
            value={defaultValue}
            error={getErrorMessage(form, field)}
          />
        );
      }}>
    </Field>
  </div>
);

export default VKLM;
