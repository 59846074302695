import React from 'react';
import { FormFooter } from '@eg/elements/FormFooter';
import { LayoutBox } from '@eg/elements/LayoutBox';
import { LoadingSpinner } from '@eg/elements/LoadingSpinner';
import { AemQuellsystem, CreateRequest, Message } from 'stg-common';
import { IS_PRODUCTION } from '../constants';
import { featureFlag } from '../FeatureFlag';
import { Feature, FFlagList } from '../FeatureFlag/FeatureFlag.types';
import { storeAppModeData } from '../helpers/agencyHelper';
import { extractAktionsNummerFromCookie } from '../helpers/cookieExtractor';
import { getIsMakler, getIsTiedAgent, modeConfig } from '../helpers/modeConfig';
import { validateMaklerQueryParams, buildParamsErrorString } from '../helpers/QueryParameterExtractor';
import {
  SESSION_STORAGE_APP_DATA,
  SESSION_STORAGE_BUSINESS_ID_KEY,
  Storage
} from '../helpers/Storage';
import { stateMachineStates } from '../routing/routes';
import { StateMachine } from '../routing/StateMachine';
import { createAngebot } from '../services/api';
import './App.css';
import { ErrorModal } from './ErrorModal';
import { FooterMakler } from './FooterMakler/FooterMakler';

export interface AppProps {
}

export interface AppState {
  businessId?: string;
  isUpdateCalling: boolean;
  isInitFetchError: boolean;
  debugLogs?: boolean;
  messages?: Message[];
}

// Get Feature flags from AEM
const featureList: Feature[] = [];

const featureFlagApi = featureFlag(featureList, IS_PRODUCTION);

export const isFeatureActive = (name: FFlagList) => featureFlagApi.isActive(name);

export class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      isUpdateCalling: false,
      isInitFetchError: false
    };
    this.initAngebotClickHandler = this.initAngebotClickHandler.bind(this);
  }

  public async componentDidMount() {
    const businessId = Storage.readItem(SESSION_STORAGE_BUSINESS_ID_KEY);

    this.setState({
      isUpdateCalling: true
    });

    if (businessId && businessId !== 'undefined') {
      this.setState({ businessId });
    } else {
      await this.initAngebotClickHandler();
    }

    const appData = await modeConfig();
    storeAppModeData(appData);

    this.setState({
      isUpdateCalling: false
    });
  }

  public componentDidUpdate(prevProps: AppProps, prevState: AppState) {
    const { businessId, debugLogs } = this.state;

    if (
      businessId && prevState.businessId !== businessId
    ) {
      Storage.writeItem(SESSION_STORAGE_BUSINESS_ID_KEY, String(businessId));
      this.setState({ businessId });
    }

    if (prevState.debugLogs !== debugLogs) {
      Storage.writeItem('debugLogs', String(debugLogs));
    }

  }

  private readonly initAngebotClickHandler = async () => {
    try {
      this.setState({
        isUpdateCalling: true,
        isInitFetchError: false
      });

      const reqWithQuellSystem: CreateRequest | { aktionsnummer: string } = {
        aktionsnummer: extractAktionsNummerFromCookie(),
        quellsystem: getIsTiedAgent()
          ? AemQuellsystem.ERGO_VERMITTLERHOMEPAGE
          : AemQuellsystem.ERGO_INTERNET // TODO: (OCTAVIO) this must be handled within the mode config
      };

      const data = await createAngebot(reqWithQuellSystem);

      if (data) {
        const { messages, businessId, debugLogs } = data;
        this.setState({
          businessId,
          messages,
          debugLogs
        });
      }
    } catch (error) {
      console.error(
        'an error occured while creating an offer -',
        JSON.stringify(error, undefined, '    ')
      );
      this.setState({
        isUpdateCalling: false,
        isInitFetchError: true
      });
    } finally {
      this.setState({
        isUpdateCalling: false
      });
    }
  };

  public render() {
    const { businessId} = this.state;
    const appDataFromStorage = Storage.readItem(SESSION_STORAGE_APP_DATA);
    const maklerParamsErrors = validateMaklerQueryParams();
    let customErrorMessage;
    if (maklerParamsErrors.length) {
      customErrorMessage = buildParamsErrorString(maklerParamsErrors);
    }
    return (
      <LayoutBox direction="column">
        <LoadingSpinner show={this.state.isUpdateCalling} />
        <ErrorModal
          businessId={this.state.businessId || ''}
          message={customErrorMessage}
          open={!!maklerParamsErrors.length || this.state.isInitFetchError}
          showContactFormBtn={false}
          onClick={this.initAngebotClickHandler}
        />
        {businessId && appDataFromStorage && <div data-cat-id={this.state.businessId} data-version="2.18.1">
          <StateMachine
            stateDefinitions={stateMachineStates}
            inputData={{
              businessId
            }}
          />
        </div>}
        <div style={{ marginTop: '4em' }}>
          <FormFooter />
        </div>
        {getIsMakler() && <FooterMakler />}
      </LayoutBox>
    );
  }

}
