import { TextValue, ValueRanges } from 'stg-common';
import { number, object, Schema, string } from 'yup';
import formatter, { EURO_WITHOUT_CENT } from '../helpers/currencyFormatter';
import { CustomMixedSchema } from './date';

export function createInsuranceFeePageSchema(valueRanges: ValueRanges): Schema<{}> {
    const insuranceFee = valueRanges.insuranceFee;
    const currencyFormatter = formatter(EURO_WITHOUT_CENT);
    const feeMinMaxError = `Der Versicherungsbeitrag liegt online bei ${currencyFormatter.format(insuranceFee.min)} 
                            bis ${currencyFormatter.format(insuranceFee.max)}. Bitte korrigieren Sie die Angabe.`;

    return object().shape({
        insuranceFee: (number() as CustomMixedSchema)
            .required('Bitte geben Sie einen Beitrag an.')
            .nonNullable()
            .min(insuranceFee.min, feeMinMaxError)
            .max(insuranceFee.max, feeMinMaxError)
            .test(
                'isMatchingFullEuro',
                `Es können nur ganze ${valueRanges.insuranceFee.increment} Eurobeträge abgesichert werden.`,
                (value: number) => value % valueRanges.insuranceFee.increment! === 0),
        paymentMethod:
            string()
                .required('Bitte wählen Sie eien Zahlweise aus.')
                .test('isValid', 'Diese Angabe ist leider nicht möglich. Bitte wählen Sie eine andere Option.',
                    value =>
                        !!valueRanges.paymentMethod.possibleValues.find((entry: TextValue) =>
                            entry.key === value)
                )
    });
}
