import { Nationality, Person } from 'stg-common';
import { GwgOptions } from '../components/GwgQuestions/GwgQuestions';
import '../helpers/InlineTooltip.css';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';
import AbstractPersonPage from './AbstractPersonPage';

export interface InsuredPersonPageData extends GwgOptions, StoreStateUpdater<InsuredPersonPageData> {
    divergingInsuredPerson: boolean;
    insuredPerson: Person;
    nationalities: Nationality[];
    showMeldung: boolean;
    agentPhone?: string;
    agentEmail?: string;
    isTiedAgent?: boolean;
    paymentMethod?: string;
}

interface InsuredPersonPageProps extends PagePropsWithValues<InsuredPersonPageData> {
    businessId: string;
}

const getHeadline = (divirgingPerson: boolean) => {
    return divirgingPerson ? 'Bitte geben Sie die Daten der versicherten Person ein:'
        : 'Persönliche Daten des Versicherungsnehmers und der zu versichernden Person';
};

function InsuredPersonPage(props: InsuredPersonPageProps) {
    return <AbstractPersonPage
        {...props}
        storeState={{
            ...props.storeState.insuredPerson,
            personId: props.storeState.insuredPerson?.personId!,
            divergingInsuredPerson: props.storeState.divergingInsuredPerson,
            politicallyExposedPerson: props.storeState.politicallyExposedPerson,
            custodian: props.storeState.custodian,
            vklm: props.storeState.vklm || '',
            agentPhone: props.storeState.agentPhone,
            agentEmail: props.storeState.agentEmail,
            accountThirdParty: props.storeState.accountThirdParty,
            nationalities: props.storeState.nationalities,
            showMeldung: props.storeState.showMeldung,
            update: props.storeState.update
        }}
        headline={getHeadline(props.storeState.divergingInsuredPerson)}
        headlineTooltip={<>Die versicherte Person muss nicht zwingend der Versicherungsnehmer sein.
            Die versicherte Person kann z. B. auch der Lebenspartner sein. Im Todesfall der versicherten Person wird die vereinbarte Summe ausgezahlt.</>}
        componentPrefix="insured-person"
        showDetailedMode={!props.storeState.divergingInsuredPerson}
        disableBirthday={true}
        isGwgRequired={!props.storeState.divergingInsuredPerson}
        trackPersonData={!props.storeState.divergingInsuredPerson}
        isNationalityRequired={true}
    />;
}

export default InsuredPersonPage;
