import { Card } from '@eg/elements/Card';
import { TooltipIcon } from '@eg/elements/TooltipIcon';
import '../../helpers/InlineTooltip.css';
import { getAgentEmail, getAgentPhone, getIsMakler, getIsTiedAgent } from '../../helpers/modeConfig';
import './Gwg.css';
import VKLM from './VKLM';
import YesNoRadio from './YesNoRadio';

export interface GwgOptions {
  politicallyExposedPerson?: boolean;
  custodian?: boolean;
  vklm?: string;
  accountThirdParty?: boolean;
}

export type UpdateGwgOptionsCallback = (values: Partial<GwgOptions>, callback?: () => void) => void;

interface GwgQuestionsDataProps {
  inputData: GwgOptions;
  updateCallback: UpdateGwgOptionsCallback;
}

export const isRequestOffer = (inputData: GwgOptions) => {
  return inputData.politicallyExposedPerson || inputData.custodian || inputData.accountThirdParty;
};

const GwgQuestions = (props: GwgQuestionsDataProps): JSX.Element => {
  const isMakler = getIsMakler();
  const isTiedAgent = getIsTiedAgent();
  const agentPhone = getAgentPhone();
  const agentEmail = getAgentEmail();

  const params = new URLSearchParams(window.location.search);
  const queryVKLM = params.get('vklm');

  let cardText = 'Diese Versicherung können Sie leider nicht online abschließen. Aber Sie können gerne einen Angebot anfordern.';
  if (isMakler) {
    cardText = 'Diese Versicherung können Sie leider nicht online abschließen.';
  } else if (isTiedAgent) {
    cardText = `Wenn Sie auf Veranlassung einer anderen Person oder als Stellvertreter abschließen möchten, Sie sich bitte an Ihren ERGO Berater: ${agentPhone}.`;
  }

  return (
    <div style={{ marginTop: '3em' }}>
      <div className="InlineTooltip">
        Wird die Versicherung für Rechnung eines Dritten abgeschlossen?
        <TooltipIcon>
          <span style={{ fontWeight: 'bold' }}>Wirtschaftlich Berechtigter</span> ist jede natürliche Person, in deren
          Eigentum oder unter deren Kontrolle der Vertragspartner letztlich steht. Aber auch die natürliche Person
          auf deren Veranlassung eine Transaktion durchgeführt oder eine Geschäftsbeziehung begründet wird. Dazu
          zählen z. B.: abweichender Beitragszahler, Vereinbarung von Rechten Dritter (z. B. unwiderrufliches
          Bezugsrecht, Abtretung oder Verpfändung).
        </TooltipIcon>
      </div>
      <YesNoRadio
        name="accountThirdParty"
        onChange={props.updateCallback}
        defaultValue={props.inputData.accountThirdParty}
      />

      <div className="InlineTooltip">
        Werden Sie im Zusammenhang mit dem Vertragsabschluss von einer anderen Person vertreten?
        <TooltipIcon>
          <span style={{ fontWeight: 'bold' }}>Stellvertreter: </span>Wird der Versicherungsnehmer beim Vertragsschluss
          durch eine andere Person vertreten (z. B. Betreuer, Vormund)?
        </TooltipIcon>
      </div>
      <YesNoRadio
        name="custodian"
        onChange={props.updateCallback}
        defaultValue={props.inputData.custodian}
      />
      {isRequestOffer(props.inputData) && (
          <>
            <Card accentColor="#8e0038" label="Versicherung leider nicht abschließbar">
              {cardText}
              {isMakler && (
                <b> Kontaktieren Sie für eine weitergehende Beratung Ihren Ansprechpartner:<br />
                  <ul>
                    <li>telefonisch: {agentPhone}</li>
                    <li>per E-Mail: {agentEmail}</li>
                  </ul>
                </b>
              )}
            </Card>
            <br />
          </>
        )
      }
      {isMakler && (
        <VKLM
          name="vklm"
          onChange={props.updateCallback}
          defaultValue={props.inputData.vklm}
          disabled={!!queryVKLM}
        />
      )}
    </div>
  );
};

export default GwgQuestions;
