import { ValueRanges } from 'stg-common';
import { number, object, Schema } from 'yup';
import { CustomMixedSchema } from './date';

export function createPaymentPeriodPageSchema(valueRanges: ValueRanges): Schema<{}> {
    const paymentPeriod = valueRanges.paymentPeriod;
    const paymentPeriodMinMaxError = `Die Beitragszahlungsdauer liegt online bei ${paymentPeriod.min} bis ${paymentPeriod.max}.
  Bitte korrigieren Sie die Angabe.`;

    return object().shape({
        paymentPeriod: (number() as CustomMixedSchema)
            .required('Bitte geben Sie die Beitragszahlungsdauer an.')
            .nonNullable()
            .min(paymentPeriod.min, paymentPeriodMinMaxError)
            .max(paymentPeriod.max, paymentPeriodMinMaxError)
    });
}
