import React from 'react';
import TooltipIcon from '@eg/elements/TooltipIcon';

interface HeadlineProps {
    children: React.ReactNode;
    subLine?: string;
    tooltip?: React.ReactElement;
}

export const Headline = (props: HeadlineProps) => {
    return <div style={{textAlign: 'center'}} data-component-id='headline'>
        <div style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'inline-block',
            fontSize: '1.5em',
            padding: props.subLine ? '1.5em 8vw 0.2em 8vw' : '1.5em 8vw 2em 8vw',
            fontWeight: 'bold'
        }}>
            {props.children}
            {props.tooltip &&
            <div style={{
                marginLeft: 'auto',
                marginRight: 'auto'
            }}>
                <TooltipIcon>
                    {props.tooltip}
                </TooltipIcon>
            </div>
            }
        </div>
        {props.subLine && <div style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'inline-block',
            padding: '0.2em 8vw 2.5em 8vw'
        }}>
            {props.subLine}
        </div>}
    </div>;
};
