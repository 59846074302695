import React from 'react';
import { Button } from '@eg/elements/Button';
import { InputRow } from '@eg/elements/InputRow';
import { LoadingSpinner } from '@eg/elements/LoadingSpinner';
import { MessageBox } from '@eg/elements/MessageBox';
import { Modal } from '@eg/elements/Modal';
import { Form as FormRaw, Formik, FormikActions, FormikValues } from 'formik';
import { DateObject, mapIsoDateStringToDateInput } from 'stg-common';
import DisclaimerInfoBox from '../components/DisclaimerInfoBox';
import { Footer } from '../components/Footer/Footer';
import GwgQuestions, { GwgOptions, isRequestOffer } from '../components/GwgQuestions/GwgQuestions';
import { Headline } from '../components/Headline';
import PersonalData, { maxLengthEmail, PersonInformations } from '../components/PersonalData';
import { ScrollToError, scrollToErrorByQuery } from '../components/ScrollToError';
import { handleAddressValidation } from '../helpers/addressValidation';
import '../helpers/InlineTooltip.css';
import { getIsMakler, getIsTiedAgent } from '../helpers/modeConfig';
import { mapPersonInformationsToPerson } from '../helpers/personHelper';
import { scrollToTop } from '../helpers/scrolling';
import { NavigationAction } from '../routing/StateMachineTypes';
import { getPerson, patchPerson, updatePerson } from '../services/api';
import { addTrackingData, trackElementClicked } from '../tracking/tracker';
import { TrackingElement } from '../tracking/trackingConstants';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';
import { createPersonalDataSchema, emailErrorMessage, emailPattern } from '../validation/PersonalDataSchema';
import { isFullofferEnabled } from '../helpers/aemHelper';

// FIXME: this lacks test unit.

const Form = FormRaw as any; // FIXME: @eg typing is a little off between versions, so I'm going to completely ignore.

export interface PersonPageData extends PersonInformations, GwgOptions, StoreStateUpdater<PersonPageData> {
  divergingInsuredPerson: boolean;
  birthdateField?: DateObject;
  agentPhone?: string;
  agentEmail?: string;
  showMeldung: boolean;
}

interface PersonPageProps extends PagePropsWithValues<PersonPageData> {
  businessId: string;
  headline: string;
  headlineTooltip?: React.ReactElement;
  componentPrefix: string;
  showDetailedMode: boolean;
  disableBirthday: boolean;
  isGwgRequired: boolean;
  isRequestOffer?: boolean;
  disableOfferNavigation?: boolean;
  globalErrors?: JSX.Element;
  trackPersonData: boolean;
  differentUserPersonId?: string;
  isNationalityRequired?: boolean;
}

interface PersonPageState {
  isInvitatioModel: boolean;
  isCorrectedAddress?: boolean;
  isLoading: boolean;
  isAddressValid?: boolean;
  intendedNavigationAction?: NavigationAction;
  disableGwgValidationForOffer: boolean;
  differentUserRequiresEmail?: boolean;
  showEmailModal: boolean;
  emailForDifferentUser?: string;
  showEmailError: boolean;
}

class AbstractPersonPage extends React.Component<PersonPageProps, PersonPageState> {
  constructor(props: Readonly<PersonPageProps>) {
    super(props);
    this.state = {
      isLoading: false,
      isInvitatioModel: false,
      disableGwgValidationForOffer: !!props.isRequestOffer,
      showEmailModal: false,
      showEmailError: false
    };
    this.parentSetState = this.parentSetState.bind(this);

    if (this.props.isRequestOffer && this.props.differentUserPersonId) {
      // tslint:disable-next-line:no-floating-promises
      getPerson(this.props.businessId, this.props.differentUserPersonId).then(person => {
        this.setState({
          differentUserRequiresEmail: !person.email
        });
      });
    }
  }

  public async componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const queryVKLM = params.get('vklm');
    if (queryVKLM) {
      this.props.storeState.update({ vklm: queryVKLM });
    }
    scrollToTop();
  }

  public render() {
    const isMakler = getIsMakler();
    const isTiedAgent = getIsTiedAgent();
    const inputData = this.props.storeState;
    const showAlert = inputData.politicallyExposedPerson || inputData.custodian || inputData.accountThirdParty;
    const shouldHideButton = (isRequestOffer(this.props.storeState) && isMakler) || (isTiedAgent && showAlert);
    return (
      <div className={this.props.componentPrefix + '-page'}
        data-component-id={this.props.componentPrefix + '-page'}>
        <Headline tooltip={this.props.headlineTooltip}>
          {this.props.headline}
        </Headline>
        <Formik
          initialValues={{
            anrede: this.props.storeState.anrede,
            vorname: this.props.storeState.vorname,
            nachname: this.props.storeState.nachname,
            birthdate: this.props.storeState.birthdate,
            birthdateField: mapIsoDateStringToDateInput(this.props.storeState.birthdate),
            adresse: this.props.storeState.adresse,
            vorwahl: this.props.storeState.vorwahl,
            rufnummer: this.props.storeState.rufnummer,
            email: this.props.storeState.email,
            staatsangehoerigkeit: this.props.storeState.staatsangehoerigkeit,
            geburtsort: this.props.storeState.geburtsort,
            politicallyExposedPerson: this.props.storeState.politicallyExposedPerson,
            custodian: this.props.storeState.custodian,
            accountThirdParty: this.props.storeState.accountThirdParty,
            divergingInsuredPerson: this.props.storeState.divergingInsuredPerson,
            nationalities: this.props.storeState.nationalities,
            personId: this.props.storeState.personId,
            vklm: this.props.storeState.vklm,
            showMeldung: this.props.storeState.showMeldung,
            update: this.props.storeState.update
          }}
          onSubmit={async (values: PersonPageData, { setErrors }: FormikActions<PersonPageData>) => {
            const validatedValues = this.validateBeforeSubmit(values);
            if (this.props.storeState.vklm !== undefined) {
              validatedValues.vklm = this.props.storeState.vklm;
            }
            this.props.storeState.update(validatedValues);
            this.setState({ isLoading: true });
            await updatePerson(this.props.businessId, mapPersonInformationsToPerson(validatedValues), validatedValues.personId)
              .then(validationResponse => {
                this.setState({ isLoading: false });

                handleAddressValidation(validationResponse, validatedValues, this.props.storeState.update, this.props.businessId,
                  validatedValues.personId, this.parentSetState, setErrors);
              })
              .catch(() => {
                this.setState({ isLoading: false });
              });

            if (this.props.trackPersonData) {
              addTrackingData({ policyHolder: validatedValues });
            }
            if (this.state.intendedNavigationAction && this.state.isAddressValid && !this.state.isCorrectedAddress) {
              if (this.state.intendedNavigationAction === NavigationAction.OFFER_WRITTEN_EMAIL && this.isEmailRequiredForDifferentUser()) {
                this.setState({ showEmailModal: true });
              } else {
                this.props.handleAction(this.state.intendedNavigationAction);
              }
            }
          }}
          validationSchema={createPersonalDataSchema(this.props.showDetailedMode, this.props.isGwgRequired, this.state.disableGwgValidationForOffer,
            this.props.isNationalityRequired)}
        >
          {form => (
            <Form noValidate data-component-id={this.props.componentPrefix + '-form'}>

              <LoadingSpinner show={this.state.isLoading} />
              <PersonalData
                form={form}
                inputData={this.props.storeState}
                updateCallback={this.props.storeState.update}
                detailedMode={this.props.showDetailedMode}
                disableBirthdate={this.props.disableBirthday}
                isCorrectedAddress={this.state.isCorrectedAddress}
                isAddressValid={this.state.isAddressValid}
                isNationalityRequired={this.props.isNationalityRequired}
              />

              {this.props.isGwgRequired &&
                <GwgQuestions
                  inputData={this.props.storeState}
                  updateCallback={this.props.storeState.update}
                />}
              <ScrollToError formik={form} />
              {this.props.storeState.showMeldung &&
                <MessageBox type="error">
                  Online können wir Ihnen den Tarif leider nicht anbieten. Bitte rufen Sie uns an  -  natürlich gebührenfrei!
                </MessageBox>}
              <br />
              {this.props.globalErrors}
              {this.props.isRequestOffer &&
                <Footer
                  hideNextButton={!isFullofferEnabled}
                  disableOfferNavigation={true}
                  enableRequestOfferButtons={true}
                  nextButtonText={'Sofort Download'}
                  handleAction={this.props.handleAction}
                  offerCallback={action => this.handleOfferCallback(action, form)}
                  onNextClick={() => {
                    trackElementClicked(TrackingElement.Button_AngebotPDFHerunterladen);
                    this.handleNavigationAction(NavigationAction.OFFER_DIRECT_ONLINE, form);
                    scrollToErrorByQuery(`[data-component-id="vp-view-card"]`);
                  }}
                />}
              {!this.props.isRequestOffer &&
                <Footer handleAction={this.props.handleAction}
                  hideNextButton={shouldHideButton || (isRequestOffer(this.props.storeState) && !isFullofferEnabled)}
                  disableOfferNavigation={this.props.disableOfferNavigation || isRequestOffer(this.props.storeState)}
                  offerCallback={action => this.setState({ disableGwgValidationForOffer: true },
                    () => this.handleOfferCallback(action, form))}
                  onNextClick={() => this.setState({ disableGwgValidationForOffer: false },
                    () => this.handleNavigationAction(NavigationAction.NEXT, form))}
                  nextButtonText={isRequestOffer(this.props.storeState) ? 'Angebot anfordern' : 'Weiter'}
                />}
              <DisclaimerInfoBox />
              {this.props.differentUserPersonId && !this.props.storeState.showMeldung && <Modal open={this.state.showEmailModal}>
                <b>Email erforderlich</b>
                <br /><br />
                Bitte geben Sie die Email-Adresse des Versicherungsnehmers an:
                <br /><br />
                <InputRow
                  label="E-Mail" id="email" name="email" maxLength={maxLengthEmail} placeholder="E-Mail"
                  data-component-id="request-offer-checkout-email"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ emailForDifferentUser: event.target.value });
                  }}
                  error={this.state.showEmailError ? 'Bitte geben Sie eine gültige E-Mail Adresse ein.' : ''}
                />
                <br /><br />
                <Button variant="tertiary" onClick={() => this.setState({ showEmailModal: false, showEmailError: false })}>Abbrechen</Button>
                <Button variant="primary" onClick={() => {
                  if (this.isEmailValid()) {
                    // tslint:disable-next-line:no-floating-promises
                    void patchPerson(this.props.businessId, { email: this.state.emailForDifferentUser }, this.props.differentUserPersonId!)
                      .then(() => {
                        this.props.handleAction(NavigationAction.OFFER_WRITTEN_EMAIL);
                        this.setState({ showEmailModal: false });
                      });
                  } else {
                    this.setState({ showEmailError: true });
                  }
                }} style={{ float: 'right' }}>Antrag anfordern</Button>
              </Modal>}
            </Form>
          )}
        </Formik>
      </div>
    );
  }

  private handleOfferCallback(action: NavigationAction, form: FormikValues) {
    form.setErrors({ email: emailErrorMessage });
    form.setFieldTouched('email');
    this.handleNavigationAction(action, form);
  }

  private handleNavigationAction(navigationAction: NavigationAction, form: FormikValues) {
    this.setState({ intendedNavigationAction: navigationAction }, () => {
      if (navigationAction === NavigationAction.OFFER_WRITTEN_EMAIL
        || navigationAction === NavigationAction.OFFER_WRITTEN_POSTAL
        || navigationAction === NavigationAction.DIRECT_JUMP_REQUEST_OFFER) {
        form.submitForm();
      }
      scrollToErrorByQuery(`span[role="alert"]`);
    });
  }

  private parentSetState(values: object) {
    this.setState(values);
  }

  private validateBeforeSubmit(values: PersonPageData): PersonPageData {
    return {
      ...this.props.storeState,
      ...createPersonalDataSchema(this.props.showDetailedMode, this.props.isGwgRequired, this.state.disableGwgValidationForOffer,
        this.props.isNationalityRequired).cast(values),
      personId: this.props.storeState.personId
    } as PersonPageData;
  }

  private isEmailRequiredForDifferentUser() {
    return this.props.isRequestOffer && !this.props.showDetailedMode && this.state.differentUserRequiresEmail;
  }

  private isEmailValid() {
    return emailPattern.test(this.state.emailForDifferentUser || '');
  }
}

export default AbstractPersonPage;
