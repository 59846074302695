import * as ibanValidation from 'iban';
import { sanitizeWhitespaces } from 'stg-common';
import { addMethod, object, ref, Reference, Schema, string, StringSchema } from 'yup';

const MAX_IBAN_CHARACTER = 34;

export function createBankingDetailsSchema(externalValidationResult: boolean): Schema<{}> {
    return object().shape({
            iban: string()
                .required('Bitte geben Sie eine gültige IBAN ein.')
                .test('maxIbanLength', 'Die IBAN darf maximal 34 Zeichen lang sein.', (iban: string) => {
                    return (sanitizeWhitespaces(iban).length <= MAX_IBAN_CHARACTER);
                })
                .test('validIban', 'Bitte geben Sie eine gültige IBAN ein.', (iban: string) => {
                    return ibanValidation.isValid(sanitizeWhitespaces(iban));
                })
                .test('validatedWithIbanService', 'Bitte geben Sie eine gültige IBAN ein.', () => externalValidationResult),
            bic: (string() as CustomMixedSchema)
                .isValidBICWhenGermanIBAN(ref('iban'), externalValidationResult)
        }
    );
}

interface CustomMixedSchema extends StringSchema {
    isValidBICWhenGermanIBAN(iban: Reference<string> , bankingDetailsValid?: boolean): this;
}

addMethod(string, 'isValidBICWhenGermanIBAN', function(iban: Reference<unknown>, externalValidationResult: boolean) {
    // tslint:disable-next-line:no-invalid-this
    return this.test({
        name: 'isValidBICWhenGermanIBAN',
        exclusive: true,
        message: `Bitte geben Sie eine gültige BIC ein.`,
        // tslint:disable-next-line:object-literal-shorthand
        test: function(bic: string | undefined) { // NOSONAR
            // tslint:disable-next-line:no-invalid-this no-any
            const sanitizedIban = iban ? sanitizeWhitespaces((this as any).resolve(iban)) : '';

            if (!sanitizedIban.toUpperCase().startsWith('DE') && bic) {
                return /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(bic) && externalValidationResult;
            } else {
                return true;
            }
        }
    });
});
