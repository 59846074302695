import React from 'react';
import { Button } from '@eg/elements/Button';
import { PdfIcon } from '@eg/elements/components/Icons';
import { MessageBox } from '@eg/elements/MessageBox';
import Modal from '@eg/elements/Modal';
import { StepButton } from '@eg/elements/StepButton';
import { ForceDownload } from '@eg/idd-components';
import { OfferState, PolicyModel, ResponseCodes } from 'stg-common';
import { AgentContactMethods } from '../components/AgentContactMethods';
import DisclaimerInfoBox from '../components/DisclaimerInfoBox';
import '../components/ErrorModal.css';
import { Footer } from '../components/Footer/Footer';
import SubmitLoadingModal from '../components/SubmitLoadingModal';
import { CONFIG_BACKEND_BASE_URL, CONFIG_IDD_BASE_URL } from '../config';
import { isFullofferEnabled } from '../helpers/aemHelper';
import { getIsErgoInternet } from '../helpers/modeConfig';
import { scrollToTop } from '../helpers/scrolling';
import { NavigationAction, StateName } from '../routing/StateMachineTypes';
import { checkOfferState, makeOrder, updateProcessModelToIModel } from '../services/api';
import { trackElementClicked, trackElementClickImmediate, trackError } from '../tracking/tracker';
import { TrackingElement, TrackingErrorType } from '../tracking/trackingConstants';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';

export interface InsuranceDocumentsPageData extends StoreStateUpdater<InsuranceDocumentsPageData> {
  businessId: string;
  policyModel?: PolicyModel;
  showAntragModal: boolean;
  offerState?: OfferState;
  showMeldung: boolean;
}

interface InsuranceDocumentsPageProps extends PagePropsWithValues<InsuranceDocumentsPageData> {
  onError: (e: Error) => void;
}

interface InsuranceDocumentsPageState {
  isOrdering: boolean;
  iddCheckboxClicked: boolean;
  iddDocumentDownloaded: boolean;
  iddTouched: boolean;
  showAntragModal: boolean;
  showMeldung: boolean;
}

class InsuranceDocumentsPage extends React.Component<InsuranceDocumentsPageProps, InsuranceDocumentsPageState> {

  // eslint-disable-next-line no-magic-numbers
  protected static readonly REFRESH_INTERVAL = 3000;

  public constructor(props: Readonly<InsuranceDocumentsPageProps>) {
    super(props);
    this.state = {
      isOrdering: false,
      iddCheckboxClicked: false,
      iddDocumentDownloaded: false,
      iddTouched: false,
      showAntragModal: this.props.storeState.showAntragModal,
      showMeldung: this.props.storeState.showMeldung
    };
    this.submitAntrag = this.submitAntrag.bind(this);
    this.gotoCheckoutPage = this.gotoCheckoutPage.bind(this);
    this.checkOfferStateInterval = this.checkOfferStateInterval.bind(this);

  }

  public async componentDidMount() {
    scrollToTop();

    switch (this.props.storeState.offerState) {
      case OfferState.REQUEST:
        this.setState({ isOrdering: true });
        this.checkOfferStateInterval();
        break;
      case OfferState.FINISH:
        this.props.handleAction(NavigationAction.NEXT);
        break;
      default:
        break;
    }
  }

  private buttonAbbrechenOnClick() {
    trackElementClickImmediate(TrackingElement.Button_Abbrechen, StateName.INSURANCE_DOCUMENTS_PAGE, true);
    this.setState({ showAntragModal: false });
    this.props.handleAction(NavigationAction.DIRECT_JUMP_REQUEST_OFFER);
  }

  public render() {
    const widthForceDownload = 250;
    const orderButtonStepNumber = 2;
    return (<>
      <br />
      <Modal open={this.state.showAntragModal}>
        <b>Onlineabschluss aktuell nicht möglich</b>
        <br /><br />
        Leider ist etwas schief gelaufen und ein Online Abschluss ist nicht möglich.
        {getIsErgoInternet() && 'Wir können Ihnen aber gerne einen Antrag zusenden.'}

        <br /><br />
        <AgentContactMethods />
        <br />
        <Button
          variant="tertiary"
          onClick={this.buttonAbbrechenOnClick.bind(this)}
          size="large"
        >
          Abbrechen
        </Button>
        {getIsErgoInternet() && isFullofferEnabled &&
          <Button variant="text-link"
            data-component-id={'online-request-next'}
            onClick={this.gotoCheckoutPage}
            style={{ float: 'right', backgroundColor: '#545241', color: '#fff' }}
            size="large">
            Antrag anfordern
          </Button>
        }

        <br /><br />
        <div className="error-modal-business-id">{this.props.storeState.businessId}</div>
      </Modal>
      <SubmitLoadingModal loading={this.state.isOrdering} />
      <h2>Bitte laden Sie zuerst alle Vertragsunterlagen herunter
        und {this.isInvitatioModel() ? 'fordern Sie den Vertrag dann an' : 'schließen Sie den Vertrag dann ab'}.
      </h2>
      <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        Mit Ihrem Klick auf "{this.isInvitatioModel() ? 'Online anfordern' : 'Vertrag Online abschließen'}" werden Ihre Daten
        verbindlich an uns übertragen.
        Innerhalb
        weniger Tage erhalten Sie dann Ihre Vertragsunterlagen per Post.
        <br />
        <br />
        <ForceDownload
          onValidate={this.handleIddValidity}
          onButtonClick={() => {
            trackElementClickImmediate(TrackingElement.Button_IDDDownload);
            this.setState({ iddTouched: true });
          }}
          onCheckboxClick={() => trackElementClickImmediate(TrackingElement.Checkbox_Beratungsverzicht)}
          onError={this.onError}
          showValidation={this.state.iddTouched && !this.iddIsValid()}
          env="production"
          pdfRequest={{ stgDiveRequest: { angebotsId: this.props.storeState.businessId } }}
          stepButtonWidth={widthForceDownload}
          antrag={this.isInvitatioModel()}
          backendHost={CONFIG_IDD_BASE_URL}
        />
        <div className="stepButton" style={{ float: 'right', textAlign: 'right' }}>
          <StepButton
            style={{ width: `auto` }}
            onClick={async () => {
              if (this.iddIsValid()) {
                try {
                  await this.submitOrder();
                } catch (err) {
                  this.props.onError(err as Error);
                }
              } else {
                this.setState({ iddTouched: true });
              }
            }}
            text={this.isInvitatioModel() ? 'Online anfordern' : 'Vertrag Online abschließen'}
            step={orderButtonStepNumber}
            data-component-id={'next-button'}
            loading={this.state.isOrdering}
            disabled={this.state.showMeldung}
          />
        </div>
        <br />
        <br />
        <br />
        {this.state.showMeldung &&
          <MessageBox type="error">
            Online können wir Ihnen den Tarif leider nicht anbieten. Bitte rufen Sie uns an  -  natürlich gebührenfrei!
          </MessageBox>
        }
      </div>
      <Footer
        disableNextButton={true}
        disableOfferNavigation={true}
        handleAction={this.props.handleAction}
        hideNextButton={true}
      />
      <DisclaimerInfoBox />
      {this.renderDocuments()}
    </>);
  }

  private isInvitatioModel() {
    return this.props.storeState.policyModel === PolicyModel.INVITATIO;
  }

  private async submitOrder() {
    this.setState({ isOrdering: true });
    trackElementClicked(TrackingElement.Button_OnlineAbschliessen);
    try {
      const response = await makeOrder(this.props.storeState.businessId);
      if (response.status === ResponseCodes.PRECONDITION_FAILED && response.showMeldung) {
        trackError(TrackingElement.Button_OnlineAbschliessen, TrackingErrorType.Sonstige);
        this.setState({ showMeldung: true, isOrdering: false }); // TODO show MELDUNG
      }
      if (response.status === ResponseCodes.PRECONDITION_FAILED && response.showAntrag) { // TODO ?!?!?
        trackError(TrackingElement.Button_Weiter, TrackingErrorType.Boni, StateName.FAKE_PRUEFE_SENDEN_PAGE, true);
        this.setState({ showAntragModal: true, isOrdering: false });
      } else {
        if (response.status !== ResponseCodes.SUCCESS) {
          throw new Error('Internal server error');
        }

        this.setState({ isOrdering: false });
        this.props.handleAction(NavigationAction.NEXT);
      }
    } catch (error) {
      trackError(TrackingElement.Button_Weiter, TrackingErrorType.Boni, StateName.FAKE_PRUEFE_SENDEN_PAGE, true);
      this.setState({ showAntragModal: true, isOrdering: false });
    }
  }

  private async submitAntrag() {
    trackElementClicked(TrackingElement.Button_AntragAnfordern);
    this.setState({ isOrdering: true, showAntragModal: false }, async () => {
      try {
        await updateProcessModelToIModel(this.props.storeState.businessId);
        await makeOrder(this.props.storeState.businessId);
        this.props.handleAction(NavigationAction.NEXT);
      } catch (err) {
        this.props.onError(err as Error);
      }
    });
  }
  private async gotoCheckoutPage() {
    trackElementClicked(TrackingElement.Button_AntragAnfordern);
    this.setState({ isOrdering: true, showAntragModal: false }, async () => {
      this.props.handleAction(NavigationAction.DIRECT_JUMP_REQUEST_OFFER);

    });
  }

  private readonly renderDocuments = () => {
    const businessId = this.props.storeState.businessId;
    return (this.isInvitatioModel() && <>
      <hr style={{ margin: '25px 0 25px 0' }} />
      <div className="esc_grid__wrapper">
        <div className="esc_col esc_col-12 esc_col-m-6">
          <a href={`${CONFIG_BACKEND_BASE_URL}/documents/${businessId}/IPID`}
            rel="noopener noreferrer"
            data-component-id="checkout-document-link-ipid"
            target="_blank" className="esc_text-link">
            <PdfIcon style={{ color: '#2f2f2f', marginRight: '10px' }} />IPID Insurance Product Information
            Dokument
          </a>
        </div>
        <div className="esc_col esc_col-12 esc_col-m-6">
          <a href={`${CONFIG_BACKEND_BASE_URL}/documents/${businessId}/AVB`}
            rel="noopener noreferrer"
            data-component-id="checkout-document-link-avb"
            target="_blank" className="esc_text-link">
            <PdfIcon style={{ color: '#2f2f2f', marginRight: '10px' }} />AVB Allgemeine
            Versicherungsbedingungen
          </a>
        </div>
        <div className="esc_col esc_col-12 esc_col-m-6">
          <a href={`${CONFIG_BACKEND_BASE_URL}/documents/${businessId}/VL`}
            rel="noopener noreferrer"
            data-component-id="checkout-document-link-vl"
            target="_blank" className="esc_text-link">
            <PdfIcon style={{ color: '#2f2f2f', marginRight: '10px' }} />Garantiewerte
          </a>
        </div>
        <div className="esc_col esc_col-12 esc_col-m-6">
          <a href={`${CONFIG_BACKEND_BASE_URL}/documents/${businessId}/DATI`}
            rel="noopener noreferrer"
            data-component-id="checkout-document-link-dati"
            target="_blank" className="esc_text-link">
            <PdfIcon style={{ color: '#2f2f2f', marginRight: '10px' }} />Informationen zur Verwendung Ihrer Daten
          </a>
        </div>
      </div>
    </>);
  };

  public onError = (e: Error) => {
    if (window.location.hostname === 'localhost') {
      console.log(e.message);
    } else {
      this.props.onError(e);
    }
  };

  private readonly handleIddValidity = (iddCheckboxClicked: boolean, iddDocumentDownloaded: boolean) => {
    this.setState({
      iddCheckboxClicked,
      iddDocumentDownloaded
    });
  };

  private readonly iddIsValid = () => {
    if (window.location.hostname === 'localhost') {
      return true;
    }
    return this.state.iddCheckboxClicked && this.state.iddDocumentDownloaded;
  };

  private readonly checkOfferStateInterval = () => {
    const interval = setInterval(async () => {
      try {
        const response = await checkOfferState(this.props.storeState.businessId);

        if (response && response.offerState === OfferState.FINISH) {
          clearInterval(interval);
          this.props.handleAction(NavigationAction.NEXT);
          this.setState({ isOrdering: false });
        }
      } catch (err) {
        this.props.onError(err as Error);
      }

    }, InsuranceDocumentsPage.REFRESH_INTERVAL);
  };
}

export default InsuranceDocumentsPage;
